import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentButton.figmaUrl.ios} codeUrl={checklists.componentButton.codeUrl.ios} checklists={checklists.componentButton.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Buttons are used to initiate an action or trigger the user to open a link. Buttons can contain a combination of clear and concise labels and can be equipped with icons. How to import your theme: Legion iOS UIKit offers four themes: ThemeAGR, ThemeEazy, ThemeIHS, ThemeLGN, and ThemeMyTEnS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`LGNButton have 4 component variants:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Solid`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outline`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Transparent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Soft`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid.png",
              "alt": null,
              "title": "LGNButton Solid"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline.png",
              "alt": null,
              "title": "LGNButton Outline"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent.png",
              "alt": null,
              "title": "LGNButton Transparent"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft.png",
              "alt": null,
              "title": "LGNButton Soft"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Solid`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .solid
`}</code></pre>
    <h3>{`Outline`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .outline
`}</code></pre>
    <h3>{`Transparent`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .transparent
`}</code></pre>
    <h3>{`Soft`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .soft
`}</code></pre>
    <div className="divi" />
    <h2>{`Size`}</h2>
    <p>{`You can customize the size of the button via the `}<inlineCode parentName="p">{`size`}</inlineCode>{` enum, where `}<inlineCode parentName="p">{`BaseLGNButton.Size`}</inlineCode>{` includes `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{` and `}<inlineCode parentName="p">{`sm`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Solid`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outline`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Transparent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Soft`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Large`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid-large.png",
              "alt": null,
              "title": "LGNButton Solid Large"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline-large.png",
              "alt": null,
              "title": "LGNButton Outline Large"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent-large.png",
              "alt": null,
              "title": "LGNButton Transparent Large"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft-large.png",
              "alt": null,
              "title": "LGNButton Soft Large"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Medium`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid-medium.png",
              "alt": null,
              "title": "LGNButton Solid Medium"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline-medium.png",
              "alt": null,
              "title": "LGNButton outline medium"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent-medium.png",
              "alt": null,
              "title": "LGNButton Transparent Medium"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft-medium.png",
              "alt": null,
              "title": "LGNButton Soft Medium"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Small`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid-small.png",
              "alt": null,
              "title": "LGNButton Solid Small"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline-small.png",
              "alt": null,
              "title": "LGNButton Outline Small"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent-small.png",
              "alt": null,
              "title": "LGNButton Solid Small"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft-small.png",
              "alt": null,
              "title": "LGNButton Soft Small"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Large`}</h3>
    <h4>{`Solid`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .solid
button.size = .lg
`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .outline
button.size = .lg
`}</code></pre>
    <h4>{`Transparent`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .transparent
button.size = .lg
`}</code></pre>
    <h4>{`Soft`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .soft
button.size = .lg
`}</code></pre>
    <h3>{`Medium`}</h3>
    <h4>{`Solid`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .solid
button.size = .md
`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .outline
button.size = .md
`}</code></pre>
    <h4>{`Transparent`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .transparent
button.size = .md
`}</code></pre>
    <h4>{`Soft`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .soft
button.size = .md
`}</code></pre>
    <h3>{`Small`}</h3>
    <h4>{`Solid`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .solid
button.size = .sm
`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .outline
button.size = .sm
`}</code></pre>
    <h4>{`Transparent`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .transparent
button.size = .sm
`}</code></pre>
    <h4>{`Soft`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .soft
button.size = .sm
`}</code></pre>
    <div className="divi" />
    <h2>{`Icons`}</h2>
    <p>{`You can insert icon in button on the left side, right side `}<inlineCode parentName="p">{`leftImage`}</inlineCode>{` and `}<inlineCode parentName="p">{`rightImage`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon Position`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Solid`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outline`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Transparent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Soft`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid-left-icon.png",
              "alt": null,
              "title": "LGNButton Solid Left Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline-left-icon.png",
              "alt": null,
              "title": "LGNButton Outline Left Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent-left-icon.png",
              "alt": null,
              "title": "LGNButton Transparent Left Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft-left-icon.png",
              "alt": null,
              "title": "LGNButton Soft Left Icon"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid-right-icon.png",
              "alt": null,
              "title": "LGNButton Solid Right Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline-right-icon.png",
              "alt": null,
              "title": "LGNButton Outline Right Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent-right-icon.png",
              "alt": null,
              "title": "LGNButton Transparent Right Icon"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft-right-icon.png",
              "alt": null,
              "title": "LGNButton Soft Right Icon"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Left Image`}</h3>
    <h4>{`Solid`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .solid
button.leftImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .outline
button.leftImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <h4>{`Transparent`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .transparent
button.leftImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <h4>{`Soft`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .soft
button.leftImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <h3>{`Right Image`}</h3>
    <h4>{`Solid`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .solid
button.rightImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <h4>{`Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .outline
button.rightImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <h4>{`Transparent`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .transparent
button.rightImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <h4>{`Soft`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.variant = .soft
button.rightImage = UIImage(named: "square.and.arrow.up.circle.fill")
`}</code></pre>
    <div className="divi" />
    <h2>{`Disabled`}</h2>
    <p>{`Make buttons appear inactive by setting the boolean `}<inlineCode parentName="p">{`disable`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Solid`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outline`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Transparent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Soft`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid-disabled.png",
              "alt": null,
              "title": "LGNButton Solid Disabled"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline-disabled.png",
              "alt": null,
              "title": "LGNButton Outline Disabled"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent-disabled.png",
              "alt": null,
              "title": "LGNButton Transparent Disabled"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft-disabled.png",
              "alt": null,
              "title": "LGNButton Soft Disabled"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.disable = true
`}</code></pre>
    <div className="divi" />
    <h2>{`Show Activity`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Solid`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Outline`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Transparent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Soft`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-solid-show-activity.png",
              "alt": null,
              "title": "LGNButton Solid Show Activity"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-outline-show-activity.png",
              "alt": null,
              "title": "LGNButton Outline Show Activity"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-transparent-show-activity.png",
              "alt": null,
              "title": "LGNButton Transparent Show Activity"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/ios-uikit/ios-uikit-button-soft-show-activity.png",
              "alt": null,
              "title": "LGNButton Soft Show Activity"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let button = LGNButton()
button.label = "Button"
button.loading = true
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      